import { Box, Flex, Heading, useColorMode } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

const rollupFeature = config.features.rollup;

const Home = () => {
  const { colorMode } = useColorMode();

  const mode = (light: string, dark: string) => {
    switch (colorMode) {
      case 'dark': return dark;
      default: return light;
    }
  };

  return (
    <Box as="main">
      <Box
        w="100%"
        background="url(/banner/plate-banner.png)"
        backgroundSize="cover"
        backgroundPosition="bottom"
        borderRadius="24px"
        padding={{ base: '24px', lg: '48px' }}
        minW={{ base: 'unset', lg: '900px' }}
        data-label="hero plate"
        position="relative"
      >
        <Box
          zIndex={ 1 }
          margin={{ base: '-24px', lg: '-48px' }}
          opacity=".5"
          borderRadius="24px" h="100%" w="100%" position="absolute"
          background={ mode('linear-gradient(90deg, #02924B 0%, #1B2323 100%)', 'linear-gradient(90deg, #00B359 0%, #1F2526 100%)') }
        />
        <Box position="relative" zIndex={ 3 }>
          <Flex mb={{ base: 6, lg: 8 }} justifyContent="space-between" alignItems="center">
            <Heading
              as="h1"
              size={{ base: 'md', lg: 'xl' }}
              lineHeight={{ base: '32px', lg: '50px' }}
              fontWeight={ 600 }
              color={ config.UI.homepage.plate.textColor }
            >
              { config.chain.name } explorer
            </Heading>
            <Box display={{ base: 'none', lg: 'flex' }}>
              { config.features.account.isEnabled && <ProfileMenuDesktop isHomePage/> }
              { config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage/> }
            </Box>
          </Flex>
          <SearchBar isHomepage/>
        </Box>
      </Box>
      <Stats/>
      <ChainIndicators/>
      <AdBanner mt={{ base: 6, lg: 8 }} mx="auto" display="flex" justifyContent="center"/>
      <Flex mt={ 8 } direction={{ base: 'column', lg: 'row' }} columnGap={ 12 } rowGap={ 8 }>
        { rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? <LatestZkEvmL2Batches/> : <LatestBlocks/> }
        <Box flexGrow={ 1 }>
          <Transactions/>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
